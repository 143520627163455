import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SmidgeDownContainer from '../components/SmidgeDownContainer'
import { graphql } from 'gatsby'

const ListItem = styled.div`
  display: flex;
  border-bottom: 1px solid rgb(161, 161, 161, 0.25);
  padding: 12px 0px;
  &:hover {
    color: #a1a1a1;
  }
  @media only screen and (max-width: 1000px) {
    display: block;
  }
`

const LinkWithoutUnderline = styled(Link)`
  text-decoration: none;
  color: black;
`

const MonoSubtitleColumn = styled.div`
  font-family: 'Inconsolata', monospace;
  width: 20%;
  font-size: 16px;
  color: #a1a1a1;
  @media only screen and (max-width: 1000px) {
    margin-bottom: 10px;
    width: 100%;
  }
`

const TitleAndDescriptionColumn = styled.div`
  width: 55%;
  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`

const ImageColumn = styled.div`
  display: flex;
  justify-content: end;
  width: 25%;
  @media only screen and (max-width: 1000px) {
    display: none;
    width: 0;
  }
`

const Description = styled.div`
  font-weight: 100;
  line-height: 22px;
  margin-top: 10px;
`

const Image = styled.img`
  width: 80%;
  border-radius: 20px;
  border: none;
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <SmidgeDownContainer>
        <ListItem>
          <MonoSubtitleColumn>{posts.length} posts</MonoSubtitleColumn>
        </ListItem>
        {posts.map(post => {
          const title = post.frontmatter.title || post.fields.slug
          return (
            <LinkWithoutUnderline
              to={`/blog${post.fields.slug}`}
              itemProp="url"
            >
              <ListItem>
                <MonoSubtitleColumn>{post.frontmatter.date}</MonoSubtitleColumn>
                <TitleAndDescriptionColumn>
                  {title}
                  {post.frontmatter.featured && (
                    <Description>{post.frontmatter.description}</Description>
                  )}
                </TitleAndDescriptionColumn>
                <ImageColumn>
                  {post.frontmatter.featured && post.frontmatter.image && (
                    <Image src={post.frontmatter.image.publicURL} />
                  )}
                </ImageColumn>
              </ListItem>
            </LinkWithoutUnderline>
          )
        })}
      </SmidgeDownContainer>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          featured
          image {
            publicURL
          }
        }
      }
    }
  }
`
