import { navigate } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import Button from '../Button'

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  @media only screen and (min-width: 550px) {
    justify-content: flex-end;
  }
  margin-top: 20px;
  button:first-child {
    margin: 0 !important;
  }
  button:last-child {
    margin: 0;
  }
`

function Navigation({ activePath }) {
  return (
    <div>
      <Wrapper>
        <Button
          isActive={activePath === '/'}
          onClick={() => navigate('/')}
        >
          About
        </Button>
        <Button
          isActive={activePath === '/blog'}
          onClick={() => navigate('/blog')}
        >
          Blog
        </Button>
        <Button
          isActive={activePath === '/contact'}
          onClick={() => navigate('/contact')}
        >
          Contact
        </Button>
      </Wrapper>
    </div>
  )
}

export default Navigation
