import React from 'react'
import Navigation from '../Navigation'

const Layout = ({ location, children }) => {
  return (
    <>
      <Navigation activePath={location?.pathname} />
      {children}
    </>
  )
}

export default Layout
